@font-face {
  font-family: 'TT Commons';
  src: url(./assets/fonts/ttf/tt_commons_regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Cormorant';
  src: url(./assets/fonts/ttf/cormorant_regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Cormorant';
  src: url(./assets/fonts/ttf/cormorant_italic.ttf) format('truetype');
  font-style: italic;
}

h1, h2, h3 {
  font-family: 'Cormorant';
  margin-top: 0;
}

a {
  text-decoration: none;
  font-family: 'TT Commons';
}

p, span, div {
  font-family: 'TT Commons';
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

input:-internal-autofill-selected {
  background-color: unset !important;
  background-image: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}